import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Observable
} from 'rxjs/Observable';
import {
  Router
} from '@angular/router';

import 'rxjs/add/operator/do';

import {
  identifierModuleUrl
} from '@angular/compiler';

import {
  Grupo,
  AnexoGrupo,
  GrupoComentario,
  GrupoCotacao
} from '../../model/cadastro/grupo.model';

import {
  GERENCIAMENTO,
  ITENSPORPAGINA
} from '../../app.config';

@Injectable()
export class GrupoService {

  constructor(private http: HttpClient) {}


  buscarPorParametro(param: string, pagina: Number): Observable < any > {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get < any > (`${GERENCIAMENTO}/api/CadastroGrupo/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {
        headers: headers
      });
    } else {
      return this.http.get < any > (`${GERENCIAMENTO}/api/CadastroGrupo/list?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {
        headers: headers
      });
    }
  }

  buscarPorId(id: number): Observable < any > {
    return this.http.get < any > (`${GERENCIAMENTO}/api/CadastroGrupo/PorId/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  remover(id: Number): Observable < any > {
    return this.http.delete < any > (`${GERENCIAMENTO}/api/CadastroGrupo/Remover/${id}`);
  }
  uploadLogo(arquivo: any, id: number): Observable < any > {

    const url = `${GERENCIAMENTO}/api/CadastroGrupo/logo/${id}/`;
    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);

    return this.http.post(url, formData, {
      headers: headers
    });
  }

  salvar(grupo: Grupo, arquivo: any = null): Observable < any > {
    if (grupo.Id > 0) {
      return this.http.put < any > (`${GERENCIAMENTO}/api/CadastroGrupo/Alterar/${grupo.Id}`, grupo);
    } else {
      let Grupo = JSON.stringify(grupo);
      const url = `${GERENCIAMENTO}/api/CadastroGrupo/Inserir`;
      const headers = new HttpHeaders().append('noloader', 'noloader');
      const formData = new FormData();
      formData.append('Arquivo', arquivo);
      formData.append('Grupo', Grupo);
      return this.http.post(url, formData, {
        headers: headers
      });
    }

  }

  uploadArquivo(arquivo: any = null, objetoClass: AnexoGrupo): Observable < any > {
    let transicao = JSON.stringify(objetoClass);
    const url = `${GERENCIAMENTO}/api/GrupoAnexo/Inserir`;
    const headers = new HttpHeaders().append('noloader', 'noloader');
    const formData = new FormData();
    formData.append('Arquivo', arquivo);
    formData.append('Dados', transicao);
    return this.http.post(url, formData, {
      headers: headers
    });
  }
  buscarAnexo(grupoid: number, pagina: Number) {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get < any > (`${GERENCIAMENTO}/api/GrupoAnexo/list/${grupoid}`, {
      headers: headers
    });
  }
  buscarComentarios(GrupoId: number) {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get < any > (`${GERENCIAMENTO}/api/GrupoComentario/list/${GrupoId}`, {
      headers: headers
    });
  }
  salvarComentario(comentario: GrupoComentario): Observable < any > {
    return this.http.post < any > (`${GERENCIAMENTO}/api/GrupoComentario/Inserir`, comentario);
  }
  removerArquivo(Id: Number): Observable < any > {
    return this.http.delete < any > (`${GERENCIAMENTO}/api/GrupoAnexo/Remover/${Id}`);
  }
  editar(objetoClass: AnexoGrupo): Observable < any > {

    return this.http.put < any > (`${GERENCIAMENTO}/api/GrupoAnexo/edit/${objetoClass.Id}`, objetoClass);
  }

  salvarCotacao(grupocotacao: GrupoCotacao): Observable < any > {
    if (grupocotacao.Id > 0) {
      return this.http.put < any > (`${GERENCIAMENTO}/api/GrupoCotacao/Alterar/${grupocotacao.Id}`, grupocotacao);
    } else {
      return this.http.post < any > (`${GERENCIAMENTO}/api/GrupoCotacao/Inserir/`, grupocotacao);
    }

  }
  buscarCotacoes(GrupoId: number) {
    const headers = new HttpHeaders().set('noloader', 't');
    return this.http.get < any > (`${GERENCIAMENTO}/api/GrupoCotacao/list/${GrupoId}`, {
      headers: headers
    });
  }
  buscarCotacaoPorId(id: number): Observable < any > {
    return this.http.get < any > (`${GERENCIAMENTO}/api/GrupoCotacao/PorId/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  removerCotacao(id: Number): Observable < any > {
    return this.http.delete < any > (`${GERENCIAMENTO}/api/GrupoCotacao/Remover/${id}`);
  }
}

const API = "https://api.frt.com.py";
export const environment = {
  production: true,
  API: API,
  ambiente: "FRT-PY",
  THEME: "FRT-PY",
  //Módulos de turismo
  VALIDAPRAZOCANCELAMENTOMANUTENCAO : false,
  USA_CASAMENTO: false,
  USA_CONTRATO: false,
  USA_CIRCUITO: false,
  USA_TURISMO: true,
  USA_PACOTE: false,
  USA_SEGURO: true,
  USA_DISNEY: true,
  USA_CARRO: false,
  USA_AEREO: true,
  USA_LOJA: false,
  USA_CASA: false,
  USA_HOTEL: true,
  USA_BETO: false,
  USA_PROMOCIONAL: true,
  USA_MARKUPIMPRESSAO: false,
  USA_ARKBEDS: false,
 USA_TERMOMETRO: true,
  USA_SOLICITARDESCONTO: true,
  USA_CADASTROAGENCIA: false,
  //Apis dos módulos de turismo
  TURISMO: API,
  TURISMO_AEREO: API,
  TURISMO_HOTEL: API,
  TURISMO_SERVICO: API,
  TURISMO_SEGURO: API,
  GERENCIAMENTO: API,
  TURISMO_CARRO: API,
  EMPRESAS: API,

  //Módulos Adicionais
  USA_BLOQUEIO_FRT: true, // Permite o uso de um botão adicional na aba bloqueios
  USA_INTRANET: true, // Permite o uso da Intranet no portal
  USA_LAMINAS: true, // Permite gerar Lâminas no portal
  USA_BANNERS: false, //Permite o uso de Banners

  //Configurações
  USA_PACOTESIMPLIFICADO: false,
  CLIENT_ID: "postman:123",
  MAPBOX_ACCESSTOKEN: 'pk.eyJ1IjoiZnJ0b3BlcmFkb3JhIiwiYSI6ImNsN3E5a3N0YTAycm00MW8wd2J5NXFoMjIifQ.NDfY7OK_ygHR567e-TVVcw',
  ITENSPORPAGINA: 20,
  MAXIMO_QUARTOS: 8,
  TAG_GOOGLE: '',
  URLSITE: '',
  PAYPAL_CLIENT_ID: "",
  ASSISTCARDLATAM: true
};
